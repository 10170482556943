import "./PlannerDashboardStyles.css";
import Event from "../Events/Event";
import CreateEvent from "../Modals/CreateEventModal/CreateEvent";
// import { notifications } from "../Notifications/Notifications";
import NotificationComponent from "../Notifications/Notification";
import React, { useEffect, useState } from "react";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import PlannerHeader from "../Header/Header";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { useNavigate } from "react-router-dom";
import SwitchRole from "../../SwitchRole";
import axios from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";
import PlannerSidebarComponent from "../PlannerSidebar";
import { useMyContext, fetchData } from "../../../../ContextApi";
import { FaCalendarAlt } from "react-icons/fa";

import { Toaster, toast } from "react-hot-toast";

import { requestFCMToken } from "../../../../utils/firebase";
import CalendarPComponent from "../../CalendarComponent/CalenderPComponent";

interface CustomJwtPayload extends JwtPayload {
  user: {
    _id: string;
    jobCategory: string;
    dateRegisteredAsVendor: string;
    username: string;
  };
}

const baseUrl = process.env.REACT_APP_API_URL;

function PlannerDashboard() {
  const [events, setEvents] = useState([]);
  const [token] = useState(sessionStorage.getItem("token"));
  const [username, setUsername] = useState("");
  const { isMobileSidebarOpen, setIsMobileSidebarOpen, setFcmToken } = useMyContext();
  const [showEventForm, setShowEventForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("dashboard");
  const { userInfo, setUserInfo } = useMyContext();
  const [eventId, setEventId] = useState("");
  const [fcmRequested, setFcmRequested] = useState(false);

  const navigate = useNavigate();
  const [, setNoOfProposal] = useState(0);

  const openCreateEventModal = () => {
    setShowEventForm(true);
  };

  const closeCreateEventModal = () => {
    setShowEventForm(false);
  };

  const openSuccessModal = () => {
    setShowEventForm(false);
    setShowSuccessMessage(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessMessage(false);
  };

  const getEvents = async () => {
    if (token) {
      try {
        const decoded: CustomJwtPayload = jwtDecode(token);
        const plannerId: string = decoded.user._id;
        const response = await axios.get(`${baseUrl}/api/events/search?plannerId=${plannerId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status === 200) {
          setEvents(response.data);
        }
      } catch (error) {
        setEvents([]);
        console.error("Error fetching events:", error);
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const result = await fetchData();
        if (result.status === 200 && result.userData) {
          setUserInfo(result.userData);
          console.log("Fetched User Data:", result.userData);
        } else {
          console.error("Failed to fetch user data:", result.errorMessage);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [setUserInfo]);

  const requestFCM = async () => {
    try {
      const fcmToken = await requestFCMToken();
      setFcmToken(fcmToken);
      console.log("FCM Token:", fcmToken);
      console.log("User Email:", userInfo.email);

      const userdata = {
        email: userInfo.email,
        fcmToken: fcmToken
      };

      const res = await axios.post(`${baseUrl}/api/auth/subscribe`, userdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      if (res.status == 200) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (userInfo?.email && !fcmRequested && Notification.permission === "default") {
      console.log(userInfo.email);
      requestFCM();
      setFcmRequested(true);
    }
  }, [userInfo, fcmRequested]);

  useEffect(() => {
    if (token) {
      try {
        const decoded: CustomJwtPayload = jwtDecode(token);
        setUsername(decoded.user.username);
        // console.log(decoded.user.username);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, [token, username]);

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className='flex flex-row h-[100vh] '>
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />

      <div className='planner-dashboard  '>
        <div className='planner-dashboard-header'>
          <PlannerHeader title={userInfo.firstname ? `Hello, ${userInfo.firstname}` : ""} hideArrow={true} />
          <Toaster position='top-center' />
          <div className='planner-dashboard-mobile-header'>
            <svg
              onClick={() => navigate(AppRoutes.PLANNER.NOTIFICATIONS)}
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='26'
              viewBox='0 0 24 26'
              fill='none'
            >
              <path
                d='M7.66667 19.6667V21C7.66667 22.0609 8.08809 23.0783 8.83824 23.8284C9.58838 24.5786 10.6058 25 11.6667 25C12.7275 25 13.7449 24.5786 14.4951 23.8284C15.2452 23.0783 15.6667 22.0609 15.6667 21V19.6667M9 3.66667C9 2.95942 9.28095 2.28115 9.78105 1.78105C10.2811 1.28095 10.9594 1 11.6667 1C12.3739 1 13.0522 1.28095 13.5523 1.78105C14.0524 2.28115 14.3333 2.95942 14.3333 3.66667C15.8645 4.3907 17.1699 5.51777 18.1094 6.92707C19.0489 8.33636 19.5873 9.97476 19.6667 11.6667V15.6667C19.767 16.4956 20.0606 17.2894 20.5238 17.9842C20.9869 18.6789 21.6067 19.2552 22.3333 19.6667H1C1.72658 19.2552 2.34641 18.6789 2.80958 17.9842C3.27275 17.2894 3.56633 16.4956 3.66667 15.6667V11.6667C3.74608 9.97476 4.2844 8.33636 5.22393 6.92707C6.16346 5.51777 7.46879 4.3907 9 3.66667Z'
                stroke='#262626'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <img
              className='planner-profile-pic'
              src={userInfo.profilePicture ? userInfo.profilePicture : "/profilePic.svg"}
              alt='profile-pic'
            />
          </div>
        </div>

        <div className='planner-dashboard-main'>
          <div className=''>
            {" "}
            <div className='event' onClick={openCreateEventModal}>
              <div>
                <span>Create event</span>
                <svg xmlns='http://www.w3.org/2000/svg' width='30' height='29' viewBox='0 0 30 29' fill='none'>
                  <path d='M22.5 14.5H7.5M15 7.25V21.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
                </svg>
              </div>
              <p>Effortless event planning, create magical moments with ease. </p>
            </div>
            <div className='sm:hidden'>
              <div className='flex items-center justify-between py-2'>
                <p className='font-medium text-lg text-[#262626]'>Calendar</p>
                <div className='p-2 rounded-md flex  items-center gap-2'>
                  <div className='text-[#1C5D99] cursor-pointer flex bg-white p-2 w-8 '>
                    <FaCalendarAlt />
                  </div>
                  Upcoming events
                </div>
              </div>
              <CalendarPComponent />
            </div>
            <div className='planner-dashboard-notifications'>
              <h2 className='planner-dashboard-subheading'>Notifications</h2>
              <div>
                <NotificationComponent setNoOfProposal={setNoOfProposal} />
              </div>
            </div>
            <div className='planner-dashboard-recent-events'>
              <h2 className='planner-dashboard-subheading'>Recent event</h2>
              <div className='recent-events'>
                {events.length > 0 ? (
                  events.map(({ title, datetime, _id }) => <Event key={_id} name={title} date={datetime} id={_id} />)
                ) : (
                  <p className='flex justify-center h-full items-center'>Your event will be added once you create one</p>
                )}
              </div>
            </div>
            <div className='report-event-flag  '>
              <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M4.78125 23H3V3.18922L3.21984 2.96984C3.38156 2.80766 4.34906 2 7.5 2C9.24422 2 11.1952 2.68953 12.9164 3.2975C14.3025 3.78734 15.6117 4.25 16.5 4.25C18.5077 4.25 19.9088 3.58203 19.9219 3.575L21 3.05281V15.2136L20.5856 15.4208C20.5177 15.4531 18.8995 16.25 16.5 16.25C15.3684 16.25 13.9509 15.9153 12.4505 15.5605C10.7639 15.162 9.02016 14.75 7.5 14.75C5.77172 14.75 5.16844 15.0116 4.78125 15.177V23Z'
                  fill='#1C5D99'
                />
              </svg>
              <p>Report event</p>
            </div>
          </div>
          <div className='hidden sm:flex'>
            <CalendarPComponent />
          </div>
        </div>
        {showSuccessMessage && (
          <SuccessModal message='Your event has been successfully created' closeModal={closeSuccessModal} eventId={eventId} />
        )}

        {showEventForm && (
          <CreateEvent openSuccessModal={openSuccessModal} closeCreateEventModal={closeCreateEventModal} setEventId={setEventId} />
        )}
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}

export default PlannerDashboard;
