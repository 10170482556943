import React, { useState } from "react";
import SwitchRole from "../../SwitchRole";
import "./PlannerProfileStyles.css";
import PlannerSidebarComponent from "../PlannerSidebar";
import ProfileComponent from "../../ProfileComponent";
import { useMyContext } from "../../../../ContextApi";

export default function PlannerProfile() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [activeTab, setActiveTab] = useState("profile");
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    const targetImage = e.target as HTMLImageElement;
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  };

  return (
    <div className='vendorpage profile-page' onClick={handleClick}>
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <ProfileComponent />

      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}
