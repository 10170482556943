import React, { useEffect, useState } from "react";
import Image from "../../assets/request-image.svg";
import HeaderComponent from "./HeaderComponent";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { dayPosition, formatPrice } from "../../utils/reuseableFunctions";
import { AppRoutes } from "../../utils/AppRoutes";
import MainLoader from "./MainLoader";

const baseUrl = process.env.REACT_APP_API_URL;

export default function OfferDetailsComponent() {
  const token = sessionStorage.getItem("token");
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [proposalDetails, setProposalDetails] = useState({
    planner: "",
    description: "",
    venue: "",
    date: "",
    proposedAmount: 0,
    offeredAmount: 0,
    status: "",
    declineReason: ""
  });


  async function getSpecificProposal() {
    try {
      const response = await axios.get(`${baseUrl}/api/proposal/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposalDetails({
          planner: response.data.proposal.planner,
          description: response.data.proposal.description,
          venue: response.data.proposal.venue,
          date: response.data.proposal.date,
          proposedAmount: response.data.proposal.proposedAmount,
          offeredAmount: response.data.proposal.offeredAmount,
          status: response.data.proposal.status,
          declineReason: response.data.proposal.declineReason
        });
      } else {
        setProposalDetails({
          planner: "",
          description: "",
          venue: "",
          date: "",
          proposedAmount: 0,
          offeredAmount: 0,
          status: "",
          declineReason: ""
        });
      }
    } catch (error) {
      setProposalDetails({
        planner: "",
        description: "",
        venue: "",
        date: "",
        proposedAmount: 0,
        offeredAmount: 0,
        status: "",
        declineReason: ""
      });
    }
  }


  useEffect(() => {
    if(proposalDetails.description == "")
      getSpecificProposal();
  }, []);

  return (
    <div className='offer-page'>
      <div className='offer-main offer-details'>
        <HeaderComponent pageName='Offer' count={count} setCount={setCount} />
        {proposalDetails.description != "" ? (
          <div className="container-offer-component">
          <div className='offer-component'>
            <div>
              <div>
                <img src={Image} alt='Request Image' />
                <p>{proposalDetails.planner}</p>
              </div>
              <div>
                <div>
                  <p>Location</p>
                  <p>{proposalDetails.venue}</p>
                </div>
                <div>
                  <p>Time</p>
                  <p>{dayjs(proposalDetails.date).format("h:mm A")}</p>
                </div>
                <div>
                  <p>Date</p>
                  <p>{dayjs(proposalDetails.date).format(`D[${dayPosition(proposalDetails.date)}], MMMM YYYY`)}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>Description</p>
                  <p>
                    {proposalDetails.description}
                  </p>
                </div>
                <div>
                  <p>Price</p>
                  <p>{formatPrice(proposalDetails.offeredAmount.toString())}</p>
                </div>
              </div>
              <div>
                <div>
                  <p>My price</p>
                  <p>{formatPrice(proposalDetails.offeredAmount.toString())}</p>
                </div>
                <div>
                  <p>Status</p>
                  <p className={proposalDetails.status}>{proposalDetails.status}</p>
                </div>
                {proposalDetails.declineReason !== undefined
                &&
                <div>
                  <p>Reason</p>
                  <p>{proposalDetails.declineReason}</p>
                </div>
                }
              </div>
              {proposalDetails.status === "declined"
              ?
              <div>
                <p>Would you like to offer a different price?</p>
                <button
                  onClick={() => navigate(AppRoutes.V_ORDER)}
                >Resend proposal</button>
              </div>
              : proposalDetails.status === "accepted"
              ? <div>
                  <p>This event has been added to your calendar</p>
                </div>
              : <div>
                  <p>you will receive notification&apos;s once vendor accept</p>
                </div>
              }
            </div>
          </div>
          </div>
        ) : (
          <div className='grow flex-1 h-[100vh] flex justify-center items-center'>
            <MainLoader />
          </div>
        )}
      </div>
    </div>
  );
}


/*
<div className='offer-component-main'>
              <div className='img-container'>
                {isScreenSmall && (
                  <svg xmlns='http://www.w3.org/2000/svg' width='11' height='20' viewBox='0 0 11 20' fill='none'>
                    <path
                      d='M10.3724 0.98491C10.2563 0.868502 10.1184 0.776145 9.96653 0.713129C9.81467 0.650113 9.65186 0.617676 9.48744 0.617676C9.32303 0.617676 9.16022 0.650113 9.00836 0.713129C8.8565 0.776145 8.71856 0.868502 8.60244 0.98491L0.292444 9.29491C0.199741 9.38742 0.126193 9.49731 0.0760117 9.61829C0.0258302 9.73926 0 9.86894 0 9.99991C0 10.1309 0.0258302 10.2606 0.0760117 10.3815C0.126193 10.5025 0.199741 10.6124 0.292444 10.7049L8.60244 19.0149C9.09244 19.5049 9.88245 19.5049 10.3724 19.0149C10.8624 18.5249 10.8624 17.7349 10.3724 17.2449L3.13244 9.99491L10.3824 2.74491C10.8624 2.26491 10.8624 1.46491 10.3724 0.98491Z'
                      fill='#262626'
                    />
                  </svg>
                )}
                <img src={Image} alt='Request Image' />
                <p>{proposalDetails.planner}</p>
              </div>
              <div className='offer-container'>
                <div>
                  <div className='desc'>
                    <p>Location</p>
                    <span>{proposalDetails.venue}</span>
                  </div>
                  <div className='desc'>
                    <p>Time</p>
                    <span>{dayjs(proposalDetails.date).format("h:mm A")}</span>
                  </div>
                  <div className='desc'>
                    <p>Date</p>
                    <span>{dayjs(proposalDetails.date).format(`Do, MMMM YYYY`)}</span>
                  </div>
                  <div className='desc'>
                    <p>Description</p>
                    <span>{proposalDetails.description}</span>
                  </div>
                  <div className='desc'>
                    <p>Price</p>
                    <span>{formatPrice(proposalDetails.offeredAmount.toString())}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <p>My price</p>
                      <p>{formatPrice(proposalDetails.proposedAmount.toString())}</p>
                    </div>
                    <div>
                      <p>Status</p>
                      <p className={proposalDetails.status}>{proposalDetails.status}</p>
                    </div>
                    {proposalDetails.status === "declined" && (
                      <div>
                        <p>Reason</p>
                        <p>{proposalDetails.declineReason}</p>
                      </div>
                    )}
                  </div>
                  {proposalDetails.status === "declined" ? (
                    <>
                      <p>Are you interested in offering another price?</p>
                      <button
                        onClick={() => {
                          if (offerId) {
                            navigate(AppRoutes.V_ORDER_REQUEST.replace(":offerId", offerId));
                          }
                        }}
                      >
                        Resend Offer
                      </button>
                    </>
                  ) : proposalDetails.status === "pending" ? (
                    <p>You will receive a notification once the vendor&apos;s accepts</p>
                  ) : (
                    <p>This event will be added to your calendar</p>
                  )}
                </div>
              </div>
            </div>
*/