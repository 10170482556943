import React, { useEffect, useState } from "react";
import "./NotificationsStyles.css";
//import SidebarComponent from "../../SidebarComponent";
import PlannerHeader from "../Header/Header";
// import Notification from "./Notification";
import PlannerSidebarComponent from "../PlannerSidebar";
import dayjs from "dayjs";
import axios from "axios";
import { useMyContext } from "../../../../ContextApi";
import SwitchRole from "../../SwitchRole";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../utils/AppRoutes";
import { notificationTimeDifference } from "../../../../utils/reuseableFunctions";

const baseUrl = process.env.REACT_APP_API_URL;

const ENDPOINT = `${baseUrl}/api/proposals/planner`;

function Notifications() {
  const token = sessionStorage.getItem("token");
  const [proposals, setProposals] = useState([]);
  const [activeTab, setActiveTab] = useState("notifications");
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const navigate = useNavigate();
  

  async function getProposals() {
    try {
      const response = await axios.get(ENDPOINT, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setProposals(response.data.proposals);
      } else {
        setProposals([]);
      }
    } catch (error) {
      setProposals([]);
    }
  }

  useEffect(() => {
    getProposals();
  }, []);

  return (
    <div className='notifications-page'>
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      {proposals.length > 0 ? (
        <div className='notifications-main'>
          <PlannerHeader title='Notification' />
          <div className='notifications-container'>
            {proposals.filter(({ createdAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
              return differenceInDays == 0;
            }).length > 0 && <p className='period mb-2'>Today</p>}
            {proposals
              .filter(({ createdAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
                return differenceInDays == 0;
              })
              .map(({ _id, createdAt, vendor, proposedAmount, eventId }) => (
                <div
                  className='mb-3 md:mb-4  notification-offer-container hover:bg-white p-1 rounded-xl'
                  key={_id}
                  onClick={() => {
                    navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
                  }}
                >
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(createdAt)}</p>
                  </div>
                  <p className='notification-message'>{`${vendor} offering ${proposedAmount} for an event`}</p>
                </div>
              ))}
            {proposals.filter(({ createdAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
              return differenceInDays <= -1 && differenceInDays >= -7;
            }).length > 0 && <p className='period mb-2'>This week</p>}
            {proposals
              .filter(({ createdAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
                return differenceInDays <= -1 && differenceInDays >= -7;
              })
              .map(({ _id, createdAt, vendor, proposedAmount, eventId }) => (
                <div
                  className='mb-3 md:mb-4  notification-offer-container hover:bg-white p-1 rounded-xl'
                  key={_id}
                  onClick={() => {
                    navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
                  }}
                >
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(createdAt)}</p>
                  </div>
                  <p className='notification-message'>{`${vendor} offering ${proposedAmount} for an event`}</p>
                </div>
              ))}
            {proposals.filter(({ createdAt }) => {
              const currentTime = dayjs();
              const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
              return differenceInDays <= -8 && differenceInDays >= -30;
            }).length > 0 && <p className='period mb-2'>This month</p>}
            {proposals
              .filter(({ createdAt }) => {
                const currentTime = dayjs();
                const differenceInDays = dayjs(createdAt).diff(currentTime, "day");
                return differenceInDays <= -8 && differenceInDays >= -30;
              })
              .map(({ _id, createdAt, vendor, proposedAmount, eventId }) => (
                <div
                  className='mb-3 md:mb-4 notification-offer-container hover:bg-white p-1 rounded-xl'
                  key={_id}
                  onClick={() => {
                    navigate(AppRoutes.PLANNER.EVENT_DETAILS.replace(":eventId", eventId));
                  }}
                >
                  <div className='notification-offer'>
                    <p>
                      <span></span>Proposal
                    </p>
                    <p className='notification-time'>{notificationTimeDifference(createdAt)}</p>
                  </div>
                  <p className='notification-message'>{`${vendor} offering ${proposedAmount} for an event`}</p>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className='flex justify-center flex-col items-center flex-1 h-full'>
          <p className='text-2xl text-[#1C5D99]'>no notifications yet</p>
          <p className='text-xl'>no proposal yet</p>
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
    </div>
  );
}

export default Notifications;
