import React from "react";
import { useState, useEffect } from "react";
import PlannerHeader from "../Header/Header";
// import SidebarComponent from "../../SidebarComponent";
import Event from "./Event";
import "./EventStyles.css";
import CreateEvent from "../Modals/CreateEventModal/CreateEvent";
import SwitchRole from "../../SwitchRole";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
import axios from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";
import PlannerSidebarComponent from "../PlannerSidebar";
import { useMyContext } from "../../../../ContextApi";

interface CustomJwtPayload extends JwtPayload {
  user: {
    _id: string;
  };
}

const baseUrl = process.env.REACT_APP_API_URL;

const MyEvents = () => {
  const [eventss, SetEvents] = useState([]);
  const [token] = useState(sessionStorage.getItem("token"));
  const [activeTab, setActiveTab] = useState("myevent");
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext();
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [showEventForm, setShowEventForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [eventId, setEventId] = useState("");
  const openCreateEventModal = () => {
    setShowEventForm(true);
  };

  const closeCreateEventModal = () => {
    setShowEventForm(false);
  };

  const openSuccessModal = () => {
    setShowEventForm(false);
    setShowSuccessMessage(true);
  };

  const closeSuccessModal = () => {
    setShowSuccessMessage(false);
  };

  const getEvent = async () => {
    if (token) {
      const decoded: CustomJwtPayload = jwtDecode(token);
      const plannerId: string = decoded.user._id;
      try {
        const response = await axios.get(`${baseUrl}/api/events/search?plannerId=${plannerId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.status == 200) {
          SetEvents(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getEvent();
  }, [showSuccessMessage]);

  return (
    <div className='planner-events-page'>
      <PlannerSidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='planner'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />

      <div className='planner-events-main'>
        <PlannerHeader title='My events' hideArrow={true} />

        {/* <EventVendors /> */}

        <div className='planner-events'>
          <div className='events-container'>
            {eventss.length > 0 ? (
              eventss.map(({ title, datetime, _id }) => <Event key={_id} name={title} date={datetime} id={_id} />)
            ) : (
              <div className='no-planner-event'>
                <h2>No event has been created by you</h2>
                <p>Create event and find trusted vendors</p>
              </div>
            )}
          </div>
        </div>
        <div className='add-event-btn' onClick={openCreateEventModal}>
          <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M32.818 16.9999H1.18164M16.9998 1.18176V32.8181'
              stroke='white'
              strokeWidth='1.97579'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='planner'
          title='Do you want switch to vendor mode?'
          description=''
          btnText1='Continue as Planner'
          btnText2='Switch to vendor'
        />
      )}
      {showSuccessMessage && (
        <SuccessModal message='Your event has been successfully created' closeModal={closeSuccessModal} eventId={eventId} />
      )}

      {showEventForm && (
        <CreateEvent openSuccessModal={openSuccessModal} closeCreateEventModal={closeCreateEventModal} setEventId={setEventId} />
      )}
    </div>
  );
};

export default MyEvents;
