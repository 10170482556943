import React, { useState, useEffect, useRef} from "react";
import displayPic1 from "../../assets/ellipse-191.svg";
import { Formik, Field, Form,} from "formik";
import { inputFieldSchema } from "../../utils/helpers";
import HeaderComponent from "./HeaderComponent";
import { FaStar } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { HiInformationCircle } from "react-icons/hi";
import toast, { Toaster } from "react-hot-toast";
import EventModal from "./Planner/Modals/ReportEventModal/EventModal";
import { convertFileToBase64 } from "../../utils/helpers";
// import { LocationOptions } from "../../utils/helpers";
import { fetchData } from "../../ContextApi";
import { useMyContext } from "../../ContextApi";
import { Spinner } from "./loader";
import imageCompression from "browser-image-compression";
import axios from "axios";
import MainLoader from "./MainLoader";
import { FaLocationDot } from "react-icons/fa6"

const baseUrl = process.env.REACT_APP_API_URL;

const Endpoint4 = `${baseUrl}/api/categories`;

interface photonSuggestion {
  properties : {
    name: string,
    city?: string,
    country?: string
  },
  geometry: {
    coordinates: [number,number]
  } 
}


export default function ProfileComponent() {
  const [count, setCount] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataState, setDataState] = useState<boolean>(false);
  const { userInfo, setUserInfo, jobCategories, setJobCategories } = useMyContext();
  const [profilePicture, setProfilePicture] = useState(displayPic1)
  const [isSelected,setIsSelected] = useState(false)
  const [suggestions,setSuggestions] = useState<photonSuggestion[]>([])
  const inputRef = useRef<HTMLInputElement | null>(null)
  const token = sessionStorage.getItem("token");
  const id = sessionStorage.getItem("_id");
  let errorCaught = false;
  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 500,
          useWebWorker: true
        });

        const base64 = await convertFileToBase64(compressedFile);

        const userData = {
          username: userInfo.firstname,
          profilePicture: base64
        };
        const response = await axios.patch(`${baseUrl}/api/users/${id}`, userData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          setProfilePicture(base64);
          setUserInfo((prev) => ({ ...prev, profilePicture: base64 }));
          toast.success("Profile picture updated successfully");
        } else {
          toast.error(response.data.errors[0]);
        }
      } catch (error) {
        console.error("Error uploading profile picture: ", error);
        toast.error("Failed to upload profile picture");
      }
    }
  };

  const fetchDataAndUpdateUserInfo = async () => {
    try {
      const { userData, status, errorMessage } = await fetchData();

      if (status === 200 && userData) {
        setUserInfo(userData);
        setProfilePicture(userData.profilePicture || displayPic1);
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    } catch (error: any) {
      if (!errorCaught) {
        toast.error(error.message);
        setIsLoading(false);
        errorCaught = true;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const socialMediaHandle = values.socialMediaHandle.startsWith("@") ? values.socialMediaHandle.substring(1) : values.socialMediaHandle;
    const userData = {
      username: values.firstname,
      firstName: values.firstname,
      lastName: values.lastname,
      jobCategory: values.jobCategory,
      socialMediaHandle: socialMediaHandle,
      homeAddress: values.location,
      profilePicture: profilePicture
    };

    try {
      const response = await axios.patch(`${baseUrl}/api/users/${id}`, userData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        setDataState(true);
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      } else {
        toast.error(response.data.errors[0]);
      }
    } catch (error: any) {
      toast.error(error.response.data.errors[0]);
    } finally {
      setSubmitting(false);
    }
  };

  const getJobCategories = async (signal: AbortSignal) => {
    try {
      const response = await axios.get(Endpoint4, {
        signal: signal
      });
      if (response.status === 200) {
        setJobCategories(response.data);
      } else {
        setJobCategories([]);
      }
    } catch (error) {
      setJobCategories([]);
    }
  };
  const fetchSuggestions = async (location: string) =>{
    if(location.length > 2 && !isSelected){
      try{
        const response = await axios.get(`https://photon.komoot.io/api/?q=${location}&limit=5`)
        setSuggestions(response.data.features)
      }catch(error){
        console.error("Error fetching suggestions:", error)
      }
    }else{
      setSuggestions([])
    }
  }

  useEffect(() => {
    const isFirstLoad = localStorage.getItem("isFirstLoad") === null;
    if (isFirstLoad) {
      setIsLoading(true);
      fetchDataAndUpdateUserInfo().then(() => {
        localStorage.setItem("isFirstLoad", "false");
      });
    } else {
      fetchDataAndUpdateUserInfo();
    }
  }, []);
  useEffect(() => {
    if (dataState) {
      fetchDataAndUpdateUserInfo();
      setDataState(false);
    }
  }, [dataState, handleImageUpload]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (jobCategories.length === 0) {
      getJobCategories(signal);
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='profile'>
      <HeaderComponent pageName='My Profile' count={count} setCount={setCount} />
      <div className='profile-main'>
        <div className=''>
          {showModal && <EventModal message='Profile successfully updated' description=' ' closeModal={() => setShowModal(false)} />}
          <Toaster position='top-center' />
        </div>
        <div className='profile-main-form '>
          {!isLoading ? (
            <div className='profile-main'>
              <div className='profile-main-form-div'>
                <div>
                  <div className='image-wrapper'>
                    <div className='image-container'>
                      <img
                        className='profile-picture'
                        src={userInfo.profilePicture != null ? userInfo.profilePicture : displayPic1}
                        alt='profile picture'
                      />
                    </div>
                  </div>

                  <div className='mt-4'>
                    <label className='font-bold text-[#1c5d99] sm:text-[18px] cursor-pointer' htmlFor='upload-input'>
                      Change photo
                    </label>
                    <div>
                      <div className='star-container gap-1'>
                        <span className='font-semibold'>5.0</span>
                        <div className='star-container gap-1'>
                          {[...Array(5)].map((_, index) => (
                            <div key={index} className='-mt-[2px]'>
                              {" "}
                              <FaStar size={15} className='text-[#1C5D99]' />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    email: userInfo?.email || "",
                    firstname: userInfo?.firstname || "",
                    lastname: userInfo?.lastname || "",
                    phonenumber: userInfo?.phonenumber || "",
                    socialMediaHandle: `@${userInfo?.socialMediaHandle}` || "",
                    jobCategory: userInfo?.jobCategory || "",
                    location: userInfo?.location || "",
                    profilePicture: userInfo?.profilePicture || ""
                  }}
                  enableReinitialize={true}
                  validationSchema={inputFieldSchema}
                  onSubmit={handleSubmit}
                >
                  {({ touched, values, isSubmitting, dirty, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <input
                          type='file'
                          accept='image/*'
                          onChange={(event) => handleImageUpload(event)}
                          style={{ display: "none" }}
                          id='upload-input'
                        />
                        <div>
                          <label htmlFor='firstname'>
                            First name
                          </label>
                          <br />
                          <div className='input-container'>
                            <Field type='text' name='firstname' id='firstname' />
                            {touched.firstname && !values.firstname ? (
                              <HiInformationCircle className='text-xl mt-[2px] text-red-500' />
                            ) : (
                              <CiEdit className='text-xl' />
                            )}
                          </div>
                        </div>
                        <div>
                          <label htmlFor='lastname'>Last name</label>
                          <br />
                          <div className='input-container'>
                            <Field type='text' name='lastname' id='lastname' />
                            {touched.lastname && !values.lastname ? (
                              <HiInformationCircle className='text-xl mt-[2px] text-red-500' />
                            ) : (
                              <CiEdit className='text-xl' />
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label htmlFor='email'>Email address</label>
                          <br />
                          <div className='input-container'>
                            <Field type='text' name='email' id='email' readOnly />
                            {touched.email && !values.email ? (
                              <HiInformationCircle className='text-xl mt-[2px] text-red-500' />
                            ) : (
                              <CiEdit className='text-xl' />
                            )}
                          </div>
                        </div>
                        <div>
                          <label htmlFor='phonenumber'>Phone number</label>
                          <br />
                          <div className='input-container'>
                            <Field type='text' name='phonenumber' id='phonenumber' readOnly />
                            {touched.phonenumber && !values.phonenumber ? (
                              <HiInformationCircle className='text-xl mt-[2px] text-red-500' />
                            ) : (
                              <CiEdit className='text-xl' />
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label htmlFor='category'>Job category</label>
                          <br />
                          <Field as='select' name='jobCategory'>
                            <option value={userInfo.jobCategory} disabled selected>
                              {userInfo.jobCategory ? userInfo.jobCategory : "-- choose a category --"}
                            </option>
                            {jobCategories.map(({ _id, name }) => {
                              return (
                                <option key={_id} value={name}>
                                  {name}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div>
                          <label htmlFor='instagram'>Instagram handle</label>
                          <br />
                          <div className='input-container'>
                            <Field
                              type='text'
                              placeholder={"@ " + userInfo?.socialMediaHandle}
                              name='socialMediaHandle'
                              id='socialMediaHandle'
                              readOnly
                            />
                            <HiInformationCircle className='text-xl mt-[2px] text-green-400' />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label htmlFor='location'>Home Address</label>
                          <br />
                          <div className='input-container'>
                          <Field type="text" ref={inputRef} 
                          onChange={
                            (e: React.ChangeEvent<HTMLInputElement>)=>
                            {
                              handleChange(e);
                              setIsSelected(false)
                              setTimeout(()=>{
                                fetchSuggestions(e.target.value)
                              },300) 
                            }} 
                            placeholder="house address" name='location' id="location" 
                          />
                          {touched.location && !values.location ? (
                              <HiInformationCircle className='text-xl mt-[2px] text-red-500' />
                            ) : (
                              <CiEdit className='text-xl' />
                            )}
                          </div>
                          {
              suggestions.length > 0 && (
                <ul
                  className="mobile-suggestion rounded-lg bg-[#f0f0f0] mt-2"
                >
                  {suggestions.map((suggestion,index) => (
                    <li
                      key={index}
                      onClick={()=> {
                        const {name, city, country} = suggestion.properties
                        setFieldValue('location',`${name}, ${city == undefined ? "" : city + ","}${city == undefined ? country : " " + country}`)
                        setIsSelected(true)
                        setSuggestions([])                        
                      }}
                      className="cursor-pointer px-2 py-1 text-left hover:bg-[#ffffff] flex flex-row gap-2 items-center"
                    >
                      <FaLocationDot />
                      <p>{`
                        ${suggestion.properties.name},
                        ${suggestion.properties.city == undefined ? "" : suggestion.properties.city + ","} 
                        ${suggestion.properties.city == undefined ? suggestion.properties.country : " " + suggestion.properties.country}                     
                      `}</p>
                    </li>
                  ))

                  }
                </ul>
                )
                }
                        </div>
                        <div>
                          <label htmlFor='instagram'>Twitter handle</label>
                          <br />
                          <div className='input-container'>
                            <input type='text' name='twitter' placeholder='@nunhereyet' id='twitter' />
                          </div>
                        </div>
                      </div>
                      {
              suggestions.length > 0 && (
                <ul
                  className="desktop-suggestion rounded-lg bg-[#f0f0f0] mb-4"
                >
                  {suggestions.map((suggestion,index) => (
                    <li
                      key={index}
                      onClick={()=> {
                        const {name, city, country} = suggestion.properties
                        setFieldValue('location',`${name}, ${city == undefined ? "" : city + ","}${city == undefined ? country : " " + country}`)
                        setIsSelected(true)
                        setSuggestions([])                        
                      }}
                      className="cursor-pointer px-2 py-1 text-left hover:bg-[#ffffff] flex flex-row gap-2 items-center"
                    >
                      <FaLocationDot />
                      <p>{`
                        ${suggestion.properties.name},
                        ${suggestion.properties.city == undefined ? "" : suggestion.properties.city + ","} 
                        ${suggestion.properties.city == undefined ? suggestion.properties.country : " " + suggestion.properties.country}                     
                      `}</p>
                    </li>
                  ))

                  }
                </ul>
                )
                }
                      <button type='submit' className='cursor-pointer' disabled={isSubmitting || !dirty}>
                        {isSubmitting ? <Spinner /> : "Submit"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div className='h-[100vh] sm:h-[80vh] lg:h-[65vh] flex items-center justify-center'>
              {" "}
              <MainLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
