import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../utils/AppRoutes";
import { Spinner } from "./loader";

interface switchRoleProps {
  isLogOutModalOpen?: boolean;
  setIsLogOutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  btnText1?: string;
  btnText2?: string;
}

const baseUrl = process.env.REACT_APP_API_URL

export default function LogOutModal({ setIsLogOutModalOpen, title, btnText1, btnText2 }: switchRoleProps) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    // console.log(targetImage)
    if (targetImage.className === "switch-role-modal") {
      setIsLogOutModalOpen(false);
    }
  }

  async function handleLogout() {
    const Endpoint = `${baseUrl}/api/auth/logout`;
    const token = sessionStorage.getItem("token");
    try {
      setIsLoading(true);
      const response = await axios.post(
        Endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.status == 200) {
        setIsLoading(false);
        setIsLogOutModalOpen(false);
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("jobcategory");
        sessionStorage.removeItem("_id");
        localStorage.clear();
        if (Cookies.get("token")) {
          Cookies.remove("token");
          Cookies.remove("expiryTime");
        }
        navigate(AppRoutes.LOGIN);
        // console.log(response.data.message)
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  return (
    <div className='switch-role-modal' onClick={handleClick2}>
      <div className='switch-role-modal-main'>
        <p className='switch-title logout-modal-title'>{title}</p>
        <div className='switch-button'>
          <button className='btn-vendor logout-modal-btn' onClick={handleLogout}>
            {isLoading ? (
              <>
                <span className='text-base'>Logging out...</span>&nbsp;
                <Spinner />
              </>
            ) : (
              btnText1
            )}
          </button>
          <button className='btn-planner' onClick={() => setIsLogOutModalOpen(false)}>
            {btnText2}
          </button>
        </div>
      </div>
    </div>
  );
}
