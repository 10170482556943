import React, { useEffect, useState, ChangeEvent } from "react";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import HeaderComponent from "./HeaderComponent";
import SidebarComponent from "./SidebarComponent";
import SwitchRole from "./SwitchRole";
import correctSvg from "../../assets/correctSvg.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AppRoutes } from "../../utils/AppRoutes";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import MainLoader from "./MainLoader";
import { dayPosition } from "../../utils/reuseableFunctions";
import { useMyContext } from "../../ContextApi";
import placeHolderImage from "../../assets/placeholderImage.svg"

const baseUrl = process.env.REACT_APP_API_URL;

export default function RequestComponent() {
  const { isMobileSidebarOpen, setIsMobileSidebarOpen } = useMyContext()
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const { offerId } = useParams();
  const [price, setPrice] = useState("");
  const [activeTab, setActiveTab] = useState("orderrequest")
  const [requstModal, setRequstModal] = useState(false);
  const [count, setCount] = useState(1);
  const [typeOfModal, setTypeOfModal] = useState(0);
  const [isSwitchUserModalOpen, setIsSwitchUserModalOpen] = useState(false);
  const [specificOffer, setSpecificOffer] = useState({
    plannerName: "",
    eventTitle: "",
    venue: "",
    time: "",
    date: "",
    description: "",
    offeredAmount: "",
    plannerProfilePic: ""
  });
  const [isProposalSuccessful, setIsProposalSuccessful] = useState(false);
  const handleClick3 = () => {
    setRequstModal(!requstModal);
  };

  const formatPrice = (inputValue: string) => {
    const numericValue = inputValue.replace(/[^0-9]/g, "");

    const formattedValue = `₦ ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    return formattedValue;
  };

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const inputValue = e.target.value;
    const formattedValue = formatPrice(inputValue);
    setPrice(formattedValue);
  }

  function handleClick2(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.className === "request-modal") {
      setTypeOfModal(0);
      setRequstModal(false);
      if (typeOfModal === 1 && isProposalSuccessful) {
        navigate(AppRoutes.V_ORDER);
      }
    }
  }

  function increaseByThousand() {
    setPrice((prev) => {
      const amount = prev.toString();
      const numericValue = amount.replace(/[^0-9]/g, "");
      const currentValue = parseInt(numericValue) + 1000;
      return formatPrice(currentValue.toString());
    });
  }

  function decreaseByThousand() {
    setPrice((prev) => {
      const amount = prev.toString();
      const numericValue = amount.replace(/[^0-9]/g, "");
      const currentValue = parseInt(numericValue) - 1000;
      return formatPrice(currentValue.toString());
    });
  }

  async function getSpecificOffer() {
    try {
      const response = await axios.get(`${baseUrl}/api/offer/${offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (response.data.status === 200) {
        setSpecificOffer(response.data.offer);
        setPrice(formatPrice(response.data.offer.offeredAmount.toString()));
      } else {
        setSpecificOffer({
          plannerName: "",
          eventTitle: "",
          venue: "",
          time: "",
          date: "",
          description: "",
          offeredAmount: "",
          plannerProfilePic: ""
        });
        setPrice("");
      }
    } catch (error) {
      console.log(error);
      navigate(-1);
      setSpecificOffer({
        plannerName: "",
        eventTitle: "",
        venue: "",
        time: "",
        date: "",
        description: "",
        offeredAmount: "",
        plannerProfilePic: ""
      });
      setPrice("");
    }
  }

  async function sendAProposal() {
    try {
      const response = await axios.post(
        `${baseUrl}/api/proposal`,
        {
          offerId: offerId,
          proposedAmount: price.replace(/[^0-9]/g, "")
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status === 200) {
        setTypeOfModal(1);
        // toast.success(response.data.message);
        setIsProposalSuccessful(true);
        // navigate(AppRoutes.V_HISTORY)
      } else {
        setRequstModal(false);
      }
    } catch (error: any) {
      if (error.response.data.status === 409) {
        toast.error("You already have a pending proposal for this offer.");
      }
      setRequstModal(false);
    }
  }

  function handleClick(e: React.MouseEvent) {
    const targetImage = e.target as HTMLImageElement;
    console.log(targetImage);
    if (targetImage.alt === "dashboard-image") return;
    setIsMobileSidebarOpen(false);
  }

  useEffect(() => {
    getSpecificOffer();
  }, []);
  return (
    <div className='vendorpage' onClick={handleClick}>
      <Toaster />
      <SidebarComponent
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        component='vendor'
        setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
      />
      <div className='orderrequest-page'>
        <div>
          <HeaderComponent pageName='Order Request' count={count} setCount={setCount} />
          {specificOffer.eventTitle ? (
            <div className='request-component'>
              <div className='request-component-main'>
                <div className='img-container'>
                  <img src={specificOffer.plannerProfilePic ? specificOffer.plannerProfilePic : placeHolderImage} alt='Request Image' />
                  <p>{specificOffer.plannerName || "Name"}</p>
                </div>
                <div className='description-container'>
                  <div className='desc'>
                    <p>Event title</p>
                    <span>{specificOffer.eventTitle}</span>
                  </div>
                  <div className='desc'>
                    <p>Location</p>
                    <span>{specificOffer.venue}</span>
                  </div>
                  <div className='desc'>
                    <p>Time</p>
                    <span>{dayjs(specificOffer.time).format("h:mm A")}</span>
                  </div>
                  <div className='desc'>
                    <p>Date</p>
                    <span>{dayjs(specificOffer.date).format(`D[${dayPosition(specificOffer.date)}] MMMM, YYYY`)}</span>
                  </div>
                  <div className='desc'>
                    <p>Description</p>
                    <span>{specificOffer.description}</span>
                  </div>
                  <div className='desc'>
                    <p>Planner&apos;s price</p>
                    <span>{formatPrice(specificOffer.offeredAmount.toString())}</span>
                  </div>
                </div>
                <div className='cta'>
                  <div className='circle' onClick={decreaseByThousand}>
                    <FaMinus />
                  </div>
                  <input type='text' onChange={handleChange} value={price} />
                  <div className='circle' onClick={increaseByThousand}>
                    <FaPlus />
                  </div>
                </div>
                <button onClick={handleClick3} className='btn-request'>
                  Send offer
                </button>
              </div>
            </div>
          ) : (
            <div className='flex-1 h-[100vh] flex justify-center items-center'>
              <MainLoader />
            </div>
          )}
        </div>
      </div>

      {requstModal && activeTab === "orderrequest" && (
        <div className='request-modal' onClick={handleClick2}>
          {typeOfModal == 0 ? (
            <div>
              <p className='para01'>Are you sure you want to submit offer?</p>
              <div>
                <button onClick={() => setRequstModal(false)}>No</button>
                <button onClick={sendAProposal}>yes</button>
              </div>
            </div>
          ) : (
            <div>
              <img src={correctSvg} />
              <p>Your offer has successfully been sent out to the Client</p>
            </div>
          )}
        </div>
      )}
      {isSwitchUserModalOpen && (
        <SwitchRole
          isSwitchUserModalOpen={isSwitchUserModalOpen}
          setIsSwitchUserModalOpen={setIsSwitchUserModalOpen}
          component='vendor'
          title='Do you want switch to planner mode?'
          description='To create an event, you need to switch to the planner.'
          btnText1='Continue as Vendor'
          btnText2='Switch to Planner'
        />
      )}
    </div>
  );
}
